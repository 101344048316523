<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/bz1.png" class="icon" />
            <input type="text" v-model="ddcode" class="input" placeholder="请输入订单编号" />
          </div>         
        </div>
        <div class="button primary mt" @click="submit">获取密码</div>
        <div class="button1 primary mt" @click="goback">取消操作</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      openId:'',
      roomcode:'',
      ddcode:'',
      state:false
    };
  },
  created() {
    this.openId = this.$route.query.openId;
    this.roomcode = this.$route.query.roomcode;
  },
  methods: {
    goback() {
      this.$router.replace({path:'/barcounter/choose_mode', query:{openId:this.openId,roomcode:this.roomcode}});
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.username)) {
        Toast('订单编号不可以为空!');
        this.state = false;
        return;
      }
      
      /*let { data } = await this.axios.post(
        '/ctid/TldjService/dj_MobileReg',
        qs.stringify({ phone: this.tel, mobilecode: this.code, username: this.username, unionid: this.unionid })
      );
      if (data.flag === '0') {
        this.state = false;
        this.$router.replace('/user/my');
      } else {
        this.state = false;
        Dialog({ message: data.message });
      }*/
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 0;
  top: 35px;
  color: rgba(0, 49, 255, 1);
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>